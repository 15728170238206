import React, { useState, useEffect } from "react";
import axios from 'axios';

const AttendanceForm = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (index, fieldName, value) => {
    const newData = [...attendanceData];
    newData[index][fieldName] = value;
    setAttendanceData(newData);
  };

  const handleAddRow = () => {
    setAttendanceData([...attendanceData, { Name: '', StartTime: '', EndTime: '', Date: '', Status: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://backendmc.marsbpo.org/api/attendance/manual-attendance",
        { attendanceData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // Optionally, you can reset the form or show a success message here
    } catch (error) {
      console.error('Error uploading attendance:', error);
      setErrorMessage('Failed to upload attendance data');
    }
  };

  return (
    <div>
      <h2>Attendance Form</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={data.Name}
                    onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={data.StartTime}
                    onChange={(e) => handleInputChange(index, 'StartTime', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={data.EndTime}
                    onChange={(e) => handleInputChange(index, 'EndTime', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={data.Date}
                    onChange={(e) => handleInputChange(index, 'Date', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={data.Status}
                    onChange={(e) => handleInputChange(index, 'Status', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={handleAddRow}>Add Row</button>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AttendanceForm;
