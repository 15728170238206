import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageGroups = () => {
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get('https://backendmc.marsbpo.org/api/pc/getAllPCs');
        const groupSet = new Set(response.data.map(pc => pc.group).filter(g => g));
        setGroups([...groupSet]);
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast.error('Failed to fetch groups');
      }
    };
    fetchGroups();
  }, []);

  const handleSendNotification = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      await axios.post('https://backendmc.marsbpo.org/api/pc/notifyGroup', 
        { group, message },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setGroup('');
      setMessage('');
      toast.success('Notification sent to group successfully');
    } catch (error) {
      console.error('Error sending notification to group:', error);
      toast.error('Failed to send notification to group');
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <div className="max-w-xl mx-auto bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-lg font-semibold mb-6">Notify Groups</h2>
        <form onSubmit={handleSendNotification}>
          <div className="mb-6">
            <label htmlFor="group" className="block text-gray-700 text-sm font-bold mb-2">Group:</label>
            <select
              id="group"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select a Group</option>
              {groups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Notification Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
              rows="4"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Send Notification
            </button>
          </div>
        </form>
      </div>
     
    </div>
  );
};

export default ManageGroups;
