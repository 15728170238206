import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

const EmployeeDetails = ({ department }) => {
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(25);
  const [selectedShift, setSelectedShift] = useState("");
  const [timeShifts, setTimeShifts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found in localStorage");
        }
        const response = await axios.get(
          `https://backendmc.marsbpo.org/api/employees/department/${department}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployees(response.data);
        // Extract all unique time shifts from the data
        const allTimeShifts = Array.from(
          new Set(response.data.map(employee => `${employee.dayStart} TO ${employee.dayEnd}`))
        );
        setTimeShifts(allTimeShifts);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [department]);

  const filteredEmployees = employees.filter(employee => {
    if (!selectedShift || selectedShift === "" || selectedShift === "Select a Shift") {
      return true; // Return true for all employees if no shift is selected
    } else {
      const [selectedStart, selectedEnd] = selectedShift.split(" TO ");
      const employeeShift = `${employee.dayStart} TO ${employee.dayEnd}`;
      return employeeShift === selectedShift;
    }
  });

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleViewProfile = (employee) => {
    navigate(`/employee/profile/${employee.id}`, { state: { employee } });
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // Handle 12 AM
    return `${formattedHour}:${minutes} ${period}`;
  };
  const convertTo12HourFormat = (timeShift) => {
    const [start, end] = timeShift.split(" TO ");
    const formattedStart = formatTime(start);
    const formattedEnd = formatTime(end);
    return `${formattedStart} TO ${formattedEnd}`;
  };

  return (
    <>
      <div className="mt-8 p-8 bg-white rounded-lg shadow-lg overflow-x-auto">
        <h3 className="text-2xl font-bold mb-4">Employees in {department}</h3>

        {/* Time Shift dropdown */}
        <div className="relative mb-4">
          <select
            onChange={(e) => setSelectedShift(e.target.value)}
            value={selectedShift}
            className="w-1/4 p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-900"
          >
            <option value="">Select a Shift</option>
            {/* Add options based on the available time shifts */}
            {timeShifts.map((timeShift, index) => (
              <option key={index} value={timeShift}>
                {convertTo12HourFormat(timeShift)}
              </option>
            ))}
          </select>
        </div>

        <table className="min-w-full">
          {/* Table header */}
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Name
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Role
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Department
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Base Salary
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Shift
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {currentItems.map((employee) => (
              <tr
                key={employee.id}
                className="border-t border-gray-300 hover:bg-gray-50 transition-all duration-300"
              >
                <td className="py-3 px-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={employee.profileImage || "https://via.placeholder.com/150"}
                        alt={`${employee.employeeName}'s profile`}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{employee.employeeName}</div>
                      <div className="text-sm text-gray-500">{employee.email}</div>
                    </div>
                  </div>
                </td>
                <td className="py-3 px-4 whitespace-nowrap">{employee.role}</td>
                <td className="py-3 px-4 whitespace-nowrap">{employee.department}</td>
                <td className="py-3 px-4 whitespace-nowrap">PKR {employee.salary}</td>
                <td className="py-3 px-4 whitespace-nowrap">{employee.dayStart} TO {employee.dayEnd}</td>
                <td className="py-3 px-4 whitespace-nowrap flex items-center space-x-2">
                  <button
                    onClick={() => handleViewProfile(employee)}
                    className="bg-blue-950 text-white px-3 py-1 rounded-full hover:bg-blue-800 focus:outline-none focus:shadow-outline-blue"
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center  my-4">
        <ReactPaginate
          pageCount={Math.ceil(filteredEmployees.length / itemsPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="bg-white text-black rounded-md px-3 py-1"
          previousLabel="Previous"
          nextLabel="Next"
        />
      </div>
    </>
  );
};

export default EmployeeDetails;
