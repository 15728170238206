import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecruitmentForm = () => {
  const initialFormData = {
    name: "",
    fatherName: "",
    cnicNo: "",
    address: "",
    city: "",
    contactNo: "",
    email: "",
    howYouCameForInterview: "", // Updated to a dropdown value
    jobRole: "", // Updated to a dropdown value
    experience: "", // Updated to a dropdown value
    experienceDetails: "",
    status: "PENDING"
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['name', 'jobRole','experience', 'howYouCameForInterview', 'cnicNo', 'address', 'city', 'contactNo', 'email'];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
        return;
      }
    }
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://backendmc.marsbpo.org/api/recruitment-forms/create-form",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success('Recruitment form submitted successfully!');
        setFormData(initialFormData);
      } else {
        throw new Error("Failed to submit recruitment form");
      }
    } catch (error) {
      console.error("Error submitting recruitment form:", error.message);
      toast.error('Failed to submit recruitment form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <form style={{ backgroundColor: '#F4F4F4' }}  className="max-w-2xl mx-auto p-12 shadow-md" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 mt-12 gap-14">
          {/* Name */}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-blue-900">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Name"
            />
          </div>

          {/* Date of Birth */}
          <div>
            <label htmlFor="fatherName" className="block text-sm font-medium text-blue-900">
              Father's Name
            </label>
            <input
              type="text"
              id="fatherName"
              name="fatherName"
              value={formData.fatherName}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Father's Name"
            />
          </div>

          {/* CNIC */}
          <div>
            <label htmlFor="cnicNo" className="block text-sm font-medium text-blue-900">
              CNIC
            </label>
            <input
              type="text"
              id="cnicNo"
              name="cnicNo"
              value={formData.cnicNo}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="CNIC"
            />
          </div>

          {/* Address */}
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-blue-900">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Address"
            />
          </div>

          {/* City */}
          <div>
            <label htmlFor="city" className="block text-sm font-medium text-blue-900">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="City"
            />
          </div>

          {/* Contact Number */}
          <div>
            <label htmlFor="contactNo" className="block text-sm font-medium text-blue-900">
              Contact Number
            </label>
            <input
              type="text"
              id="contactNo"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Contact Number"
            />
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-blue-900">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Email"
            />
          </div>

          {/* How You Came For Department (Dropdown) */}
          <div>
            <label htmlFor="howYouCameForInterview" className="block text-sm font-medium text-blue-900">
              How did you come to know about us?
            </label>
            <select
              id="howYouCameForInterview"
              name="howYouCameForInterview"
              value={formData.howYouCameForInterview}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            >
              <option value="">Select an option</option>
              <option value="Facebook">Facebook</option>
              <option value="Indeed">Indeed</option>
              <option value="TikTok">TikTok</option>
              <option value="Walk-In">Walk-In</option>
              <option value="Reference">Reference</option>
            </select>
          </div>

          {/* Job Role (Dropdown) */}
          <div>
            <label htmlFor="jobRole" className="block text-sm font-medium text-blue-900">
              Job Role
            </label>
            <select
              id="jobRole"
              name="jobRole"
              value={formData.jobRole}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            >
              <option value="">Select an option</option>
              <option value="CSR">CSR</option>
              <option value="Junior Closer">Junior Closer</option>
              <option value="Senior Closer">Senior Closer</option>
              <option value="Verifier">Verifier</option>
              <option value="Self-Verifier">Self-Verifier</option>
            </select>
          </div>

          {/* Experience (Dropdown) */}
          <div>
            <label htmlFor="experience" className="block text-sm font-medium text-blue-900">
              Experience
            </label>
            <select
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            >
              <option value="">Select an option</option>
              <option value="Fresh">Fresh</option>
              <option value="Experienced">Experienced</option>
            </select>
          </div>

          {/* Experience Details */}
          <div className="col-span-2">
            <label htmlFor="experienceDetails" className="block text-sm font-medium text-blue-900">
              Experience Details
            </label>
            <textarea
              id="experienceDetails"
              name="experienceDetails"
              value={formData.experienceDetails}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Experience Details"
              rows="3"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="col-span-2 w-full bg-blue-950 text-white p-2 rounded-md"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecruitmentForm;
