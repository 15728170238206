import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateAttendance = ({ isOpen, onClose, attendanceData }) => {
  const [updatedAttendance, setUpdatedAttendance] = useState({ ...attendanceData });
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAttendance((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setUpdatedAttendance({ ...attendanceData });
  }, [attendanceData]);

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://backendmc.marsbpo.org/api/attendance/update-attendance/${attendanceData.id}`,
        updatedAttendance,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage("Attendance updated successfully.");
      toast.success("Attendance updated successfully.");
    } catch (error) {
      console.error("Error updating attendance:", error);
      toast.error("Failed to update attendance. Please try again.");
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div
          style={{ backgroundColor: "#F4F4F4" }}
          className=" rounded-lg w-1/2 shadow-lg shadow-black p-8"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Update Attendance</h2>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
          <form>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={updatedAttendance.name}
                onChange={handleInputChange}
                className="form-input mt-1 block w-full"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="startTime"
                className="block text-sm font-medium text-gray-700"
              >
                Start Time:
              </label>
              <input
                type="text"
                id="startTime"
                name="startTime"
                value={updatedAttendance.startTime}
                onChange={handleInputChange}
                className="form-input mt-1 block w-full"
                contentEditable
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="endTime"
                className="block text-sm font-medium text-gray-700"
              >
                End Time:
              </label>
              <input
                type="text"
                id="endTime"
                name="endTime"
                value={updatedAttendance.endTime}
                onChange={handleInputChange}
                className="form-input mt-1 block w-full"
                contentEditable
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Date:
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={updatedAttendance.date}
                onChange={handleInputChange}
                className="form-input mt-1 block w-full"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700"
              >
                Status:
              </label>
              <select
                id="status"
                name="status"
                value={updatedAttendance.status}
                onChange={handleInputChange}
                className="form-select mt-1 block w-full"
              >
                <option value="ABSENT">ABSENT</option>
                <option value="PRESENT">PRESENT</option>
                <option value="LATE">LATE</option>
                <option value="LEAVE">LEAVE</option>
                <option value="WORK FROM HOME">WORK FROM HOME</option>
              </select>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleUpdate}
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
              >
                Update
              </button>
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </form>
          {successMessage && (
            <div className="mt-4 p-2 bg-green-100 text-green-800 rounded">
              {successMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateAttendance;
