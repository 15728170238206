import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddInventoryForm = ({ totalInventory ,fetchTotalInventory }) => {
  const [items, setItems] = useState({
    headphones: 0,
    laptops: 0,
    mouse: 0,
    keyboards: 0,
    pcs: 0,
    lcds: 0
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    for (const key in items) {
      if (items[key] < 0) {
        toast.error('Inventory counts cannot be negative');
        return;
      }
    }

    try {
      const response = await axios.post('https://backendmc.marsbpo.org/api/inventory/add', items);

      toast.success(response.data.message);
      // Reset form inputs
      setItems({
        headphones: 0,
        laptops: 0,
        mouse: 0,
        keyboards: 0,
        pcs: 0,
        lcds:0
      });
      fetchTotalInventory();

    } catch (error) {
      toast.error('Failed to add inventory');
    } 
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setItems(prevItems => ({
      ...prevItems,
      [id]: parseInt(value)
    }));
  };

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 rounded-lg shadow-xl space-y-4">
        <h1 className='text-2xl font-bold'>Add Inventory</h1>
        {Object.entries(items).map(([itemName, quantity]) => (
          <div key={itemName} className="flex flex-col">
            <label htmlFor={itemName} className="mb-1 capitalize">{itemName}</label>
            <input 
              type="number" 
              id={itemName} 
              value={quantity} 
              onChange={handleChange} 
              className="w-full border-b px-4 py-2 rounded border-gray-300 focus:outline-none focus:border-blue-500" 
            />
          </div>
        ))}
        <button type="submit" className="w-full bg-blue-950 text-white py-2 px-4 rounded focus:outline-none hover:bg-blue-700">Add Inventory</button>
      </form>
    </div>
  );
};

export default AddInventoryForm;
