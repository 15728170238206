import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const AttendanceChart = ({ date }) => {
  const [attendanceData, setAttendanceData] = useState({
    date: '',
    presentPercentage: 0,
    presentCount: 0,
    absentCount: 0,
    leaveCount: 0,
    lateCount: 0,
    workFromHomeCount: 0, // New state for WFH employee count
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(`https://backendmc.marsbpo.org/api/attendance/attendance-summary/${date}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAttendanceData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [date]);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded',
      },
    },
    legend: {
      position: 'top',
    },
    xaxis: {
      categories: ['Leave', 'Present', 'Late', 'Absent', 'Work from Home'], // Include 'Work from Home' category
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const chartSeries = [
    {
      name: 'Count',
      data: [attendanceData.leaveCount, attendanceData.presentCount, attendanceData.lateCount, attendanceData.absentCount, attendanceData.wfhCount], // Include WFH count in the data array
    },
  ];

  return (
    <div className="rounded-lg p-4">
      <h2 className="text-2xl font-semibold mb-4">Attendance Summary </h2>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={400} />
    </div>
  );
};

export default AttendanceChart;
